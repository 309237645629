h3 {
    font-size: 24px;
    color: #00827B;
    margin: 16px 0;
}
.benefits-boxes {
    clear: right;
}
.benefits-boxes div {
    float: left;
    width: 288px;
    margin: 0 16px 16px 0;
    height: 90px;
}
.benefits-boxes h2 {
    margin: 0 0 8px 80px;
}
.benefits-boxes p {
    margin: 4px 0 4px 80px;
    font-size: 12px;
    line-height: 1.5em;
}
.benefits-boxes i {
    display: block;
    width: 48px;
    height: 48px;
    padding: 8px;
    margin: 0 8px;
    float: left;
    background: url('/img/icons/benefits.png') no-repeat 8px 8px;
}
.benefits-boxes i.icon-2 {
    background-position: -88px 8px;
}
.benefits-boxes i.icon-3 {
    background-position: -184px 8px;
}
.benefits-boxes i.icon-4 {
    background-position: -280px 8px;
}
.benefits-boxes i.icon-5 {
    background-position: -376px 8px;
}
.benefits-boxes i.icon-6 {
    background-position: -472px 8px;
}
.benefits-boxes i.icon-7 {
    background-position: -568px 8px;
}
.benefits-boxes i.icon-8 {
    background-position: -664px 8px;
}
.causes {
    font-size: 14px;
    line-height: 2em;
}
.quotes {
    font-size: 14px;
}
.quotes i {
    font-style: normal;
    font-size: 64px;
    color: #999999;
    width: 30px;
    height: 24px;
    overflow: hidden;
    display: block;
    float: left;
    line-height: 1em;
}
.quotes i.quote-close {
    margin-top: 16px;
    text-align: right;
}
.quotes span {
    float: left;
    line-height: 3em;
}
.services h2 {
    margin: 0 0 8px;
    font-size: 1.4em;
}
.services ul {
    margin: 0;
}
.steps div {
    float: left;
    width: 176px;
    margin-right: 5px;
}
.steps h2 {
    margin-left: 48px;
    color: #00827B;
}
.steps i.number {
    float: left;
    font-size: 48px;
    color: #999999;
    font-weight: bold;
    overflow: hidden;
    width: 40px;
    font-style: normal;
    position: absolute;
}
.steps p {
    font-size: 14px;
    line-height: 2em;
    margin-left: 32px;
}
.half {
    float: left;
    width: 464px;
    margin-right: 16px;
}
.btn-primary {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #006dcc;
    background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
    background-image: -o-linear-gradient(top, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.btn-bottom {
	width: 220px;
	margin: 16px;
}
